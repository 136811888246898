import { init } from '@getpopsure/i18n-react';
import * as Sentry from '@sentry/browser';

import { translations } from 'locales';

const isStagingOrDev =
  process.env.REACT_APP_ENVIRONMENT === 'staging' ||
  process.env.NODE_ENV === 'development';

init({
  phrase: {
    projectId: '913885755080ffbbdf64f5b5eb5da678',
    allowInContextEditor: isStagingOrDev,
  },
  debug: false,
  resources: translations,
  lng: 'en', // TODO: Remove this when German translation is dones
  supportedLngs: ['en'], // TODO: Remove this when German translation is done
  saveMissing: true,
  missingKeyHandler: (_lng, _ns, key) => {
    Sentry.captureMessage(`[Translation] Missing key: ${key}`, 'error');
  },
});
