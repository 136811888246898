import React, { useEffect, useState } from 'react';
import { tariffLongNameMapping } from '@getpopsure/private-health-insurance-pricing-engine';
import {
  BottomOrRegularModal,
  Button,
  Checkbox,
  EditIcon,
  illustrations,
  InformationBox,
  Input,
  Radio,
} from '@popsure/dirty-swan';
import {
  capitalizeName,
  englishFormattedEuroCurrency,
} from '@getpopsure/public-utility';
import { Trans, TranslationFn, useTranslation } from '@getpopsure/i18n-react';

import { ViewProps } from '..';
import { BankAccountDetails } from 'models';

import Loading from 'components/loading';

import healthInsuranceLogo from './img/health-insurance-logo.svg';
import styles from './style.module.scss';
import { ibanValidator } from 'validators';

import GenericError from 'components/genericError';
import infoSvg from './img/info.svg';
import {
  getValidReferralCode,
  ReferrerCodeVerify,
} from 'features/referralEngine';
import { Address } from '@getpopsure/public-models';
import moment from 'moment';
import { RiskLevel } from 'models/price';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { PolicyDetails } from 'components/PolicyDetails/PolicyDetails';
import { PriceBreakdown } from 'components/PriceBreakdown/PriceBreakdown';
import { ReferralCodeForm } from 'components/ReferralCode/ReferralCode';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAsyncOperationErrorMessage,
  getAsyncOperationInProgress,
} from 'selectors/asyncOperation';
import { REFERRAL_VERIFY_OPERATION_ID } from 'features/referralEngine/constants';
import { AppState } from 'reducers';
import { getReferralCodeError } from 'features/referralEngine/selectors';
import { flushOperationError } from 'actions/asyncOperation';
import {
  flushReferralEngineError,
  updateReferralInfo,
  verifyReferralCode,
} from 'features/referralEngine/actions';

export const getPersonalInfoMapping = ({
  t,
  firstName,
  lastName,
  dateOfBirth,
  address,
  phoneNumber,
  email,
}: {
  t: TranslationFn;
  firstName?: string;
  lastName?: string;
  dateOfBirth: string;
  address?: Address;
  phoneNumber: string;
  email: string;
}): { key: string; value: string }[] => [
  {
    key: t('page.sepa.personalinfo.name', 'Name'),
    value: `${firstName} ${lastName}`,
  },
  {
    key: t('page.sepa.personalinfo.dateofbirth', 'Date of birth'),
    value: dateOfBirth,
  },
  {
    key: t('page.sepa.personalinfo.address', 'Address'),
    value: `${address?.street} ${address?.houseNumber}, ${address?.postcode} ${address?.city}`,
  },
  {
    key: t('page.sepa.personalinfo.telephone', 'Telephone'),
    value: phoneNumber,
  },
  { key: t('page.sepa.personalinfo.email', 'Email'), value: email },
];

const preExistingConditionTitle = ({
  level,
  reason,
}: {
  level: RiskLevel;
  reason?: string;
}): string => {
  if (level === 'X' || !reason) {
    return 'Risk surcharge';
  }

  return reason;
};

export default ({
  quote,
  user,
  onSubmitBankAccountDetail,
  submitting,
  submittingError,
  loading,
  loadingError,
  fetchData,
  questionnaire,
}: ViewProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [sepaError, setSepaError] = useState('');
  const [IBAN, setIBAN] = useState('');
  const [isSepaModalOpen, setIsSepaModalOpen] = useState(false);
  const [paymentMethodModal, setPaymentMethodModal] = useState(false);
  const [isSepaAreaModalOpen, setIsSepaAreaModalOpen] = useState(false);
  const [SEPAMandate, setSEPAMandate] = useState(false);
  const [hasIban, setHasIban] = useState(true);
  const [
    displayRiskSurchargeInfoNotice,
    setDisplayRiskSurchargeInfoNotice,
  ] = useState(false);
  const [submittedPaymentOption, setSubmittedPaymentOption] = useState<
    'IBAN' | 'LATER' | null
  >(null);

  const { flagsReady } = useFlagsStatus();
  const allowUndefinedIban = useFlag('private_signup_set_iban_later');
  const displayNewCheckout = useFlag('private_signup_checkout_revamp');

  useEffect(() => {
    setError(submittingError ?? '');
  }, [submittingError]);

  useEffect(() => {
    setError('');
    setSepaError('');
  }, [hasIban]);

  useEffect(() => {
    if (questionnaire === undefined) {
      throw new Error('Trying to sign sepa mandate without a questionnaire');
    }

    fetchData(questionnaire.id);
  }, [fetchData, questionnaire]);

  const validReferrerCode = useSelector(getValidReferralCode);
  const referralCodeLoading = useSelector((state: AppState) =>
    getAsyncOperationInProgress(state, REFERRAL_VERIFY_OPERATION_ID)
  );
  const referralEngineError = useSelector((state: AppState) =>
    getAsyncOperationErrorMessage(state, REFERRAL_VERIFY_OPERATION_ID)
  );
  const referrerCodeError = useSelector(getReferralCodeError);

  const onSubmitReferralCode = async (newCode: string) => {
    const upperCaseCode = newCode.toUpperCase();
    dispatch(verifyReferralCode(upperCaseCode));

    if (referralEngineError || referrerCodeError) {
      dispatch(flushReferralEngineError());
      dispatch(flushOperationError(REFERRAL_VERIFY_OPERATION_ID));
    }
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    setError('');
    setSepaError('');

    if (hasIban && ibanValidator(IBAN) === false) {
      setError(
        t(
          'page.sepa.setupsepa.incorrectiban.error',
          'The IBAN you have entered was not recognised. Please re-enter a valid IBAN and try again'
        )
      );
      return;
    }

    if (hasIban && !SEPAMandate) {
      setSepaError(
        t(
          'page.sepa.setupsepa.sepaMandate.error',
          'To setup direct debit from your bank account we need your consent. Click the check box above to give your consent.'
        )
      );
      return;
    }

    const bankAccountDetails: BankAccountDetails = {
      iban: hasIban ? IBAN : undefined,
    };

    onSubmitBankAccountDetail(bankAccountDetails);
  };

  if (loading === true || quote === undefined || user === undefined) {
    return <Loading />;
  }

  if (loadingError !== undefined) {
    return <GenericError error={loadingError} />;
  }

  if (
    !questionnaire ||
    !questionnaire.answers ||
    !questionnaire.answers.personalInfo ||
    !questionnaire.answers.personalInfo.phoneNumber
  ) {
    throw new Error(`Incomplete questionnaire: ${questionnaire?.id}`);
  }

  const {
    ownContributions,
    employerContributions,
    totalMonthlyPrice,
    riskFactorSurcharge,
    riskLevel,
    sickDayPayout,
  } = quote;

  const { firstName, lastName, dateOfBirth, email } = user;

  const {
    answers: {
      personalInfo: { address, phoneNumber },
    },
  } = questionnaire;

  const personalInfoMapping = getPersonalInfoMapping({
    t,
    firstName,
    lastName,
    dateOfBirth,
    email,
    address,
    phoneNumber,
  });

  const policyName = tariffLongNameMapping[quote.tariff];

  const totalMonthlyPriceWithoutRiskSurcharge =
    totalMonthlyPrice - riskFactorSurcharge;

  const startDate = questionnaire.answers.insuranceHistory?.startDate;

  const onRemoveCode = () => {
    dispatch(updateReferralInfo({ referralCode: undefined }));
  };

  if (displayNewCheckout) {
    return (
      <div className={styles.submitPage}>
        <div className={`${styles.submitSection} bg-white`}>
          <div className="wmx7 w100">
            <h1 className="p-h1 mb48  ta-start">
              {t('page.checkout.title', 'Checkout')}
            </h1>
            <PolicyDetails
              productCard={{
                productName: t(
                  'page.checkout.policyDetails.privateHealth',
                  'Private health'
                ),
                planName: policyName,
                details: [
                  {
                    id: 'startDate',
                    label: t(
                      'page.checkout.policyDetails.startDate',
                      'Start date'
                    ),
                    value: moment()
                      .add(1, 'month')
                      .startOf('month')
                      .format('DD MMM YYYY'),
                  },
                  {
                    id: 'deductible',
                    label: t(
                      'page.checkout.policyDetails.deductible',
                      'Deductible'
                    ),
                    value: englishFormattedEuroCurrency(quote.deductible, true),
                  },
                  {
                    id: 'incomeProtection',
                    label: t(
                      'page.checkout.policyDetails.incomeProtection',
                      'Income protection'
                    ),
                    value: sickDayPayout
                      ? `${englishFormattedEuroCurrency(
                          sickDayPayout * 30,
                          true
                        )}/month`
                      : '',
                  },
                ],
              }}
              policyDetails={[
                {
                  id: 'name',
                  label: t(
                    'page.checkout.policyDetails.policyholder.name',
                    'Name'
                  ),
                  value: questionnaire.answers.personalInfo.name
                    ? capitalizeName(questionnaire.answers.personalInfo.name) ??
                      ''
                    : '',
                },
                {
                  id: 'dateOfBirth',
                  label: t(
                    'page.checkout.policyDetails.policyholder.dateOfBirth',
                    'Date of birth'
                  ),
                  value: moment(
                    questionnaire.answers.personalInfo.dateOfBirth
                  ).format('DD MMM YYYY'),
                },
                {
                  id: 'email',
                  label: t(
                    'page.checkout.policyDetails.policyholder.email',
                    'Email'
                  ),
                  value: email ?? '',
                },
              ]}
            />
            <div className="mt48">
              <ReferralCodeForm
                onSubmitCode={onSubmitReferralCode}
                loading={referralCodeLoading}
                error={referralEngineError || referrerCodeError}
                validReferrerCode={Boolean(validReferrerCode)}
                onRemoveCode={onRemoveCode}
                startDate={startDate}
              />
            </div>
          </div>
        </div>
        <div className={styles.submitSection}>
          <form onSubmit={handleOnSubmit} className="w100 wmx7">
            <PriceBreakdown
              productName={`${t(
                'page.checkout.policyDetails.privateHealth',
                'Private health'
              )} ${policyName}`}
              basePrice={totalMonthlyPrice}
              subTotalPrice={totalMonthlyPrice}
              finalPrice={{
                primaryLabel: t(
                  'page.checkout.priceBreakdown.whatYouPay',
                  'What you pay'
                ),
                primaryValue: ownContributions ?? totalMonthlyPrice,
                primaryDescription: t(
                  'page.checkout.priceBreakdown.billedOnTheFirst',
                  'Billed on the 1st'
                ),
                secondaryLabel: employerContributions
                  ? t(
                      'page.checkout.priceBreakdown.whatYourEmployerPays',
                      'What your employer pays'
                    )
                  : undefined,
                secondaryValue: employerContributions ?? undefined,
              }}
              infoBox={
                riskLevel.level !== 'RG0' ? (
                  <InformationBox
                    title={t(
                      'page.checkout.priceBreakdown.riskLevel.infoBox.title',
                      'Pre-existing conditions may affect coverage and pricing'
                    )}
                  >
                    {t(
                      'page.checkout.priceBreakdown.riskLevel.infoBox.description',
                      'If changes are needed, our team will review your application and provide personalized advice. Any adjustments will only be made with your consent.'
                    )}
                  </InformationBox>
                ) : undefined
              }
              payComponent={
                <>
                  {submittedPaymentOption === null && (
                    <Button
                      loading={loading}
                      className="w100 mt24"
                      type="button"
                      onClick={() => setPaymentMethodModal(true)}
                      data-cy="add-payment-method"
                    >
                      {t(
                        'page.checkout.paymentMethod.button',
                        'Add payment method'
                      )}
                    </Button>
                  )}
                  {submittedPaymentOption && (
                    <>
                      <h3 className="p-h3 mb16">
                        {t('page.checkout.paymentMethod', 'Payment method')}
                      </h3>
                      <button
                        type="button"
                        onClick={() => setPaymentMethodModal(true)}
                        className={styles.paymentMethodButton}
                      >
                        {submittedPaymentOption === 'IBAN' && (
                          <div className="d-flex ai-center">
                            <div className={styles.bankIconSection}>
                              <img
                                className={styles.bankIcon}
                                src={illustrations.bank}
                                alt="bank"
                              />
                            </div>
                            <p className="p-h4 ml16">{IBAN}</p>
                          </div>
                        )}
                        {submittedPaymentOption === 'LATER' && (
                          <p className="p-h4">
                            {t(
                              'page.checkout.paymentMethod.setupLater.label',
                              'Setup later'
                            )}
                          </p>
                        )}
                        <EditIcon
                          className={styles.editIcon}
                          size={20}
                          noMargin
                        />
                      </button>
                      <InformationBox
                        variant="information"
                        title={t(
                          'page.checkout.paymentMethod.privateHealthPayments.infoBox.title',
                          'Private health payments'
                        )}
                        className="mt16"
                      >
                        {submittedPaymentOption === 'LATER' &&
                          t(
                            'page.checkout.paymentMethod.privateHealthPayments.infoBox.later.description',
                            "We'll request your IBAN after confirmation."
                          )}{' '}
                        {t(
                          'page.checkout.paymentMethod.privateHealthPayments.infoBox.description',
                          'Payments are deducted between the 1st–3rd of each month. If approval takes time, your first payment may include previous months.'
                        )}
                      </InformationBox>
                    </>
                  )}
                  {submittedPaymentOption && (
                    <Button
                      loading={loading}
                      className="w100 mt24"
                      data-cy="button-submit-application"
                      type="submit"
                    >
                      {t('page.checkout.buyPolicy.button', 'Buy policy')}
                    </Button>
                  )}
                  <p className="p-p--small tc-grey-500 mt24 ta-center">
                    {t(
                      'page.checkout.buyPolicy.description',
                      'First payments are charged on the policy start date. Coverage is valid only after a confirmation email is received and the start date has passed.'
                    )}
                  </p>
                </>
              }
            />
          </form>
        </div>
        <BottomOrRegularModal
          isOpen={paymentMethodModal}
          onClose={() => setPaymentMethodModal(false)}
          title={t('page.checkout.paymentMethod.modal.title', 'Payment method')}
          footer={
            <Button
              className="w100"
              disabled={!SEPAMandate || (hasIban && !IBAN)}
              data-cy="add-payment-method-continue"
              onClick={() => {
                if (hasIban && ibanValidator(IBAN) === false) {
                  setError(
                    t(
                      'page.sepa.setupsepa.incorrectiban.error',
                      'The IBAN you have entered was not recognised. Please re-enter a valid IBAN and try again'
                    )
                  );
                  return;
                }
                setSubmittedPaymentOption(hasIban ? 'IBAN' : 'LATER');
                setPaymentMethodModal(false);
              }}
            >
              {t('page.checkout.paymentMethod.modal.continue', 'Continue')}
            </Button>
          }
        >
          <div className="pr24 pl24 pb24">
            <p className="p-p">
              {t(
                'page.checkout.paymentMethod.modal.description',
                'Your private health contributions, including employer-supported amounts, are securely deducted. Ensuring uninterrupted coverage.'
              )}
            </p>
            <Radio
              classNames={{
                container: 'mt16',
                option: styles.radioOption,
              }}
              inlineLayout
              wide
              data-cy="iban-provide-radio"
              onChange={(values) => setHasIban(values.includes('now'))}
              options={{
                now: t('page.checkout.paymentMethod.modal.payNow', 'Now'),
                later: t(
                  'page.checkout.paymentMethod.modal.setUpLater',
                  'Set up later'
                ),
              }}
              value={hasIban ? 'now' : 'later'}
            />
            {hasIban && (
              <Input
                data-cy="sepa-mandate-field"
                className={`mt16 wmx100 d-block ${styles.iban}`}
                placeholder="IBAN"
                required={true}
                value={IBAN}
                error={error}
                onChange={(e) => {
                  setIBAN(e.target.value);
                }}
              />
            )}
            <Checkbox
              bordered={false}
              onChange={(values) =>
                setSEPAMandate(values.includes('sepaMandate'))
              }
              options={{
                sepaMandate: (
                  <>
                    <p className="p-p w100">
                      {t(
                        'page.checkout.legal.agreement',
                        'I agree to set up direct debit by SEPA mandate and confirm to have full access to the provided account.'
                      )}
                      <button
                        className="ml8 p-p bg-transparent"
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => setIsSepaModalOpen(true)}
                        type="button"
                      >
                        {t(
                          'page.checkout.legal.agreement.button',
                          'See agreement'
                        )}
                      </button>
                    </p>
                  </>
                ),
              }}
              value={SEPAMandate ? ['sepaMandate'] : []}
              classNames={{ container: 'mt16' }}
              wide
            />
          </div>
        </BottomOrRegularModal>
        <BottomOrRegularModal
          title={t('page.checkout.iban.legal.title', 'SEPA Mandate')}
          onClose={() => setIsSepaModalOpen(false)}
          isOpen={isSepaModalOpen}
        >
          <p className={`p-p p24 ${styles.legal}`}>
            {t(
              'page.checkout.iban.legal.text',
              'By setting up direct debit I authorize Hallesche Krankenversicherung a.G. to send instructions to my bank to debit my account each month and to my bank to execute the direct debit in accordance with the instructions from Hallesche Krankenversicherung a.G. (Creditor ID: DE89ZZZ00000031444). I confirm that I am authorized to set up direct debit for this account. Hallesche Krankenversicherung a.G. will send the instructions to my bank at least six days prior to the first due date. In case of repeated direct debit with equal or fixed direct debit amounts a single information before the first direct debit and the details of the due dates is sufficient. As part of my rights, I am entitled to a refund from my bank under the terms and conditions of the agreement with my bank. A refund must be claimed within 8 weeks starting from the date on which my account was debited.'
            )}
          </p>
        </BottomOrRegularModal>
      </div>
    );
  }

  return (
    <>
      <div className="p-body">
        <img
          className={`d-block mt16 ${styles.logo}`}
          src={healthInsuranceLogo}
          alt=""
        />
        <div className="p-h4 tc-primary-500 ta-center mt16">
          {t('page.sepa.step.title', 'Step 3')}
        </div>
        <h1 className="p-h1 ta-center ws100">
          {t('page.sepa.title', 'Submit your application!')}
        </h1>
        <p className={`p-p ta-center wmx8 mt8 ${styles.description}`}>
          {t(
            'page.sepa.description',
            'This is the final step of your application. Below you can review your payment information.'
          )}
        </p>
        {riskLevel.level === 'X' && (
          <div className="p-notice--warning mt24">
            <h4 className="p-h4">
              {t('page.sepa.medicalhistory.warning.title', 'Please be aware')}
            </h4>
            <p className="p-p mt8">
              {t(
                'page.sepa.medicalhistory.warning.description',
                'Please be aware that pre-existing health conditions may affect your coverage and pricing. Our team will carefully review your application and provide personalized advice if any changes are required. Your consent will be requested before any adjustments are made.'
              )}
            </p>
          </div>
        )}
        <form onSubmit={handleOnSubmit}>
          <div className={`mt32 ${styles['informations-container']}`}>
            <div className="w100">
              <div className={styles['policy-information']}>
                <h2 className="p-h2">
                  {t('page.sepa.policyinfo.title', 'Policy information')}
                </h2>
                {startDate && (
                  <div className={`mt24 ${styles.cell}`}>
                    <div>
                      {t('page.sepa.policyinfo.startDate', 'Start date')}
                    </div>
                    <div className={`ta-right ${styles.value}`}>
                      {moment(startDate).format('DD MMM YYYY')}
                    </div>
                  </div>
                )}
                <div className={`mt24 ${styles.cell}`}>
                  <div>
                    {t('page.sepa.policyinfo.name', '{{policyName}} Policy', {
                      policyName,
                    })}
                  </div>
                  <div className={`ta-right ${styles.value}`}>
                    {englishFormattedEuroCurrency(
                      totalMonthlyPriceWithoutRiskSurcharge,
                      true
                    )}
                  </div>
                </div>
                <div className="p-p tc-grey-500 mt8">
                  {t(
                    'page.sepa.deductible',
                    '{{deductible}} annual deductible',
                    {
                      deductible: englishFormattedEuroCurrency(
                        quote.deductible,
                        true
                      ),
                    }
                  )}
                </div>

                {riskLevel.level !== 'RG0' && (
                  <>
                    <h4 className="p-h4 mt24">
                      {t(
                        'page.sepa.preexistingconditions.title',
                        'Pre-existing conditions'
                      )}
                    </h4>
                    <div className={`mt16 ${styles.cell}`}>
                      <div className="d-flex">
                        <div>{preExistingConditionTitle(riskLevel)}</div>
                        <img
                          className="c-pointer ml8"
                          role="button"
                          tabIndex={0}
                          src={infoSvg}
                          alt={t(
                            'page.sepa.risklevel',
                            'risk level information'
                          )}
                          onClick={() => {
                            setDisplayRiskSurchargeInfoNotice(true);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              setDisplayRiskSurchargeInfoNotice(true);
                            }
                          }}
                        />
                      </div>
                      <div className={`ta-right ${styles.value}`}>
                        {riskLevel.level === 'X'
                          ? '???'
                          : englishFormattedEuroCurrency(riskFactorSurcharge)}
                      </div>
                    </div>
                  </>
                )}
                <hr className={`mt24 ${styles.separator}`} />
                {employerContributions && (
                  <div className={`mt16 ${styles.cell}`}>
                    <div>
                      {t(
                        'page.sepa.monthlytotal.employercontribution.title',
                        'Your employer will pay up to'
                      )}
                    </div>
                    <div className={styles.value}>
                      {englishFormattedEuroCurrency(employerContributions)}
                    </div>
                  </div>
                )}
                <div className={`mt16 ${styles.cell}`}>
                  <div className={styles.total}>
                    {t('page.sepa.monthlytotal.title', 'Your monthly total')}
                  </div>
                  <div className={styles.total}>
                    {englishFormattedEuroCurrency(
                      ownContributions ?? totalMonthlyPrice
                    )}
                  </div>
                </div>

                <ReferrerCodeVerify />
              </div>
            </div>
            <div className="w100">
              <div className={styles['personal-information']}>
                <h2 className="p-h2">
                  {t('page.sepa.personalinfo.title', 'Personal information')}
                </h2>
                <div className="mt8">
                  {personalInfoMapping.map(({ key, value }) => (
                    <div
                      key={key}
                      className={`mt16 tc-grey-500 ${styles.cell}`}
                    >
                      <div className={styles['personal-info-key']}>{key}</div>
                      <div className="ta-right">{value}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles['personal-information']}>
                <h2 className="p-h2">
                  {t('page.sepa.setupsepa.title', 'Set up direct debit')}
                </h2>
                {flagsReady && allowUndefinedIban && (
                  <Radio
                    classNames={{
                      container: 'mt16',
                      option: styles.radioOption,
                    }}
                    inlineLayout
                    wide
                    data-cy="iban-provide-radio"
                    onChange={(values) => setHasIban(values.includes('now'))}
                    options={{
                      now: t('page.sepa.setupNow', 'Now'),
                      later: t('page.sepa.setUpLater', 'Set up later'),
                    }}
                    value={hasIban ? 'now' : 'later'}
                  />
                )}

                {hasIban ? (
                  <>
                    <Input
                      data-cy="sepa-mandate-field"
                      className={`mt16 wmx100 d-block ${styles.iban}`}
                      placeholder="IBAN"
                      required={true}
                      value={IBAN}
                      error={error}
                      onChange={(e) => {
                        setIBAN(e.target.value);
                      }}
                    />
                    <div className="mt24">
                      <Checkbox
                        bordered={false}
                        onChange={(values) =>
                          setSEPAMandate(values.includes('sepaMandate'))
                        }
                        options={{
                          sepaMandate: t(
                            'page.sepa.legal.agreement',
                            'I agree to set up direct debit by SEPA mandate and confirm to have full access to the provided account.'
                          ),
                        }}
                        value={SEPAMandate ? ['sepaMandate'] : []}
                      />

                      {sepaError && (
                        <InformationBox className="mt16" variant="error">
                          {sepaError}
                        </InformationBox>
                      )}
                      <div className="ta-center mt24">
                        <button
                          onClick={() => setIsSepaModalOpen(true)}
                          className={styles.button}
                          type="button"
                        >
                          {t('page.sepa.iban.legal.action', 'View agreement')}
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <Trans i18nKey="page.sepa.iban.setupLater">
                    <div className="p-p mt16">
                      If you don’t have a bank account from any country in the{' '}
                      <button
                        onClick={() => setIsSepaAreaModalOpen(true)}
                        className={styles.button}
                        type="button"
                      >
                        SEPA area
                      </button>{' '}
                      yet, you can buy the policy now and provide your IBAN
                      later in your account — preferably before the insurance
                      start date.
                    </div>
                    <div className="p-p mt16">
                      Alternatively, you can also transfer the money yourself
                      each month. Please make sure the first payment is done
                      immediately when your coverage starts. Otherwise, your
                      coverage may be canceled.
                    </div>
                  </Trans>
                )}
              </div>
            </div>
          </div>

          <button
            data-cy="sepa-mandate-pay"
            type="submit"
            className={`p-btn--primary ${
              submitting ? 'p-btn--loading' : ''
            } mt24 ws3 ${styles['pay-button']}`}
          >
            {t('page.sepa.continue.action', 'Buy now')}
          </button>
        </form>
        <p
          className={`p-p--small d-block mt24 wmx8 ta-center ${styles['legal-text']}`}
        >
          {t(
            'page.sepa.legal.startdate.1',
            'Your cover will begin on {{coverageStartDate}} if you click "Buy now" but only if you have received the official confirmation of insurance coverage.',
            { coverageStartDate: startDate }
          )}{' '}
          <span className="fw-bold">
            {t(
              'page.sepa.legal.startdate.2',
              'A withdrawal of the contract can be declared within 14 days after receiving the official confirmation of insurance coverage.'
            )}
          </span>
        </p>
      </div>
      <BottomOrRegularModal
        title={'Risk surcharge'}
        isOpen={displayRiskSurchargeInfoNotice}
        onClose={() => setDisplayRiskSurchargeInfoNotice(false)}
      >
        <p className={`p-p ${styles['modal-content']}`}>
          {t(
            'page.sepa.risksurchage.notice.warning',
            'Please be aware that pre-existing health conditions may affect your coverage and pricing. Our team will carefully review your application and provide personalized advice if any changes are required. Your consent will be requested before any adjustments are made.'
          )}
        </p>
      </BottomOrRegularModal>

      <BottomOrRegularModal
        title={t('page.sepa.iban.legal.title', 'SEPA Mandate')}
        onClose={() => setIsSepaModalOpen(false)}
        isOpen={isSepaModalOpen}
      >
        <p className={`p-p p24 ${styles.legal}`}>
          {t(
            'page.sepa.iban.legal.text',
            'By setting up direct debit I authorize Hallesche Krankenversicherung a.G. to send instructions to my bank to debit my account each month and to my bank to execute the direct debit in accordance with the instructions from Hallesche Krankenversicherung a.G. (Creditor ID: DE89ZZZ00000031444). I confirm that I am authorized to set up direct debit for this account. Hallesche Krankenversicherung a.G. will send the instructions to my bank at least six days prior to the first due date. In case of repeated direct debit with equal or fixed direct debit amounts a single information before the first direct debit and the details of the due dates is sufficient. As part of my rights, I am entitled to a refund from my bank under the terms and conditions of the agreement with my bank. A refund must be claimed within 8 weeks starting from the date on which my account was debited.'
          )}
        </p>
      </BottomOrRegularModal>

      <BottomOrRegularModal
        title={t('page.sepaArea.title', 'SEPA Area')}
        onClose={() => setIsSepaAreaModalOpen(false)}
        isOpen={isSepaAreaModalOpen}
      >
        <p className={`p-p p24 ${styles.legal}`}>
          {t(
            'page.sepaArea.text',
            "If you don't have a bank account from a country in the SEPA area with Euro as the currency, you can buy the policy now and provide your IBAN later in your account."
          )}
        </p>
      </BottomOrRegularModal>
    </>
  );
};
