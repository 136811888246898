import { useTranslation } from '@getpopsure/i18n-react';
import React from 'react';

type Detail = {
  id: string;
  label: string;
  value: string;
};

interface PolicyDetailsProps {
  productCard: {
    productName: string;
    planName?: string;
    details: Detail[];
  };
  policyDetails: Detail[];
  additionalPolicyDetails?: {
    id: string;
    title: string;
    details: Detail[];
  }[];
}

interface DetailRowProps {
  detail: Detail;
  greyLabel?: boolean;
}

const DetailRow = ({ greyLabel = false, detail }: DetailRowProps) => (
  <div className="d-flex jc-between mt16 ai-center">
    <p className={`p-p ${greyLabel ? 'tc-grey-600' : ''}`}>{detail.label}</p>
    <p className="p-p">{detail.value}</p>
  </div>
);

export const PolicyDetails = ({
  productCard,
  policyDetails,
  additionalPolicyDetails,
}: PolicyDetailsProps) => {
  const { t } = useTranslation();
  return (
    <div className="wmx7 w100">
      <div className="bg-primary-50 br8 p24">
        <div className="d-flex ai-center mb24">
          <h3 className="p-h3 mr8">{productCard.productName}</h3>
          <h3 className="p-h3 tc-grey-500">{productCard.planName}</h3>
        </div>
        {productCard.details.flatMap((detail) => {
          if (!detail.value) return <></>;
          return <DetailRow detail={detail} key={detail.id} />;
        })}
      </div>
      <div className="mt48">
        <h2 className="p-h2">
          {t('paymentScreen.policyDetails.title', 'Policy details')}
        </h2>
        {policyDetails.flatMap((detail) => {
          if (!detail.value) return <></>;
          return <DetailRow detail={detail} greyLabel key={detail.id} />;
        })}
        {additionalPolicyDetails?.flatMap((section) => {
          const hasAnyDefinedValue = section.details.some(
            (detail) => !!detail.value
          );
          if (!hasAnyDefinedValue) {
            return <></>;
          }
          return (
            <div className="mt32" key={section.id}>
              <h4 className="p-h4">{section.title}</h4>
              {section.details.flatMap((detail) => {
                if (!detail.value) return <></>;
                return <DetailRow detail={detail} greyLabel key={detail.id} />;
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
