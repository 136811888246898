import React from 'react';
import { useTranslation } from '@getpopsure/i18n-react';
import { Button } from '@popsure/dirty-swan';
import { useGoogleLogin } from '@react-oauth/google';
import * as Sentry from '@sentry/browser';

import { useFlag } from '@unleash/proxy-client-react';
import classNames from 'classnames';

import googleIcon from '../assets/google-icon.svg';
import styles from './GoogleLoginButton.module.scss';
import { loginWithGoogle } from 'network/api';

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID_OAUTH ?? '';

interface GoogleLoginButtonProps {
  disabled?: boolean;
  className?: string;
  onSuccess: () => void;
  onLoginError: (errorMessage: string) => void;
}

const GoogleLoginButtonInternal = ({
  className,
  disabled,
  onLoginError,
  onSuccess,
}: GoogleLoginButtonProps) => {
  const { t } = useTranslation();

  const errorMessages = {
    default: t(
      'page.question.email.googleLoginError.default',
      'Something went wrong.'
    ),
    notConnected: t(
      'page.question.email.googleLoginError.notConnected',
      "Your account isn't linked to Google yet - please log in with your email and sign-in code first, then link your Google account in Account settings."
    ),
  };
  const loginWithGoogleRequest = async (token: string) => {
    try {
      const response = await loginWithGoogle(token);

      if (response?.status === 201) {
        onSuccess();
      } else {
        onLoginError(errorMessages.notConnected);

        Sentry.captureMessage('Endpoint failed to return a success response', {
          tags: {
            feature: 'GOOGLE_LOGIN',
          },
          extra: {
            description: 'Failed to login with Google',
          },
        });
      }
    } catch (error) {
      onLoginError(errorMessages.default);
      Sentry.captureException(error, {
        tags: {
          feature: 'GOOGLE_LOGIN',
        },
        extra: {
          description: 'Failed to login with Google',
        },
      });
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: ({ code }) => loginWithGoogleRequest(code),
    onError: (error) => {
      onLoginError(errorMessages.default);
      Sentry.captureException(error, {
        tags: {
          feature: 'GOOGLE_LOGIN',
        },
        extra: {
          description: 'Google login failed',
        },
      });
    },
    flow: 'auth-code',
  });

  return (
    <Button
      className={classNames(styles.button, 'tc-grey-900', className)}
      onClick={() => googleLogin()}
      disabled={disabled}
      type="button"
      variant="filledBlack"
      leftIcon={<img alt="Google Icon" src={googleIcon} />}
    >
      {t('components.email.googleSignin', 'Continue with Google')}
    </Button>
  );
};

export const GoogleLoginButton = (props: GoogleLoginButtonProps) => {
  const hasGoogleLogin = useFlag('private_signup_google_login');

  if (!hasGoogleLogin || window.Cypress) {
    return <></>;
  }

  if (!googleClientId) {
    throw new Error('Google Client ID for signin is not defined');
  }

  return <GoogleLoginButtonInternal {...props} />;
};
