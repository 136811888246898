import { Button, GiftIcon, Input, XIcon } from '@popsure/dirty-swan';
import classNames from 'classnames';
import { ChangeEvent, FormEvent, useState } from 'react';
import React from 'react';

import styles from './style.module.scss';
import { TFunction, Trans, useTranslation } from '@getpopsure/i18n-react';
import { REFERRAL_ENGINE_TC_URL } from 'features/referralEngine/constants';
import moment from 'moment';

interface ReferralCodeFormProps {
  onSubmitCode(code: string): void;
  onRemoveCode(): void;
  loading?: boolean;
  error?: string;
  validReferrerCode?: boolean;
  startDate?: string;
}

const getErrorMessage = (t: TFunction, error?: string): string | undefined => {
  if (!error) return;

  if (error === 'NEW_CUSTOMERS_ONLY')
    return t(
      'referralengine.form.error.newCustomersOnly',
      'This code has been used. Referral codes work only for first policy purchases.'
    );

  if (error === 'NOT_VALID')
    return t(
      'referralengine.form.error.notValid',
      'This code is no longer active. Please use another.'
    );

  return t(
    'referralengine.form.error.default',
    'Code not valid. Please check and try again.'
  );
};

const DATE_FORMAT = 'DD MMM YYYY';

export const ReferralCodeForm = ({
  onSubmitCode,
  onRemoveCode,
  loading,
  error,
  validReferrerCode,
  startDate,
}: ReferralCodeFormProps) => {
  const { t } = useTranslation();
  const [code, setCode] = useState<string>('');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) =>
    setCode(e.target.value);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (code) {
      onSubmitCode(code);
    }
  };

  const payoutDate =
    startDate && moment().date() === 1
      ? moment(startDate).add(3, 'months').format(DATE_FORMAT)
      : moment(startDate)
          .add(1, 'month')
          .startOf('month')
          .add(3, 'months')
          .format(DATE_FORMAT);

  return (
    <form onSubmit={handleSubmit}>
      <h2 className="p-h2 mb16">
        {t('referralengine.reward.title', 'Rewards')}
      </h2>
      <div className={styles.container}>
        <div className={styles.inputWrapper}>
          <Input
            placeholder={t('referralengine.reward.placeholder', 'Promo code')}
            error={getErrorMessage(t, error)}
            value={code}
            onChange={handleInputChange}
            className={styles.input}
          />
          {validReferrerCode && (
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles.inputCheckmark}
            >
              <circle
                cx="12"
                cy="12"
                r="10"
                fill="#85DCB4"
                stroke="#85DCB4"
                strokeWidth="2"
              />
              <path
                d="M8.25 12L10.9167 14.5L16.25 9.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>

        <Button
          type="submit"
          disabled={loading || validReferrerCode}
          className={classNames('bg-purple-50 tc-purple-500', styles.button)}
        >
          {t('referralengine.reward.submit', 'Apply')}
        </Button>
      </div>
      {validReferrerCode && (
        <>
          <div
            className={classNames(
              'br8 my16 py8 d-flex jc-between',
              styles.rewardInfoContainer
            )}
          >
            <div className="d-flex fd-row ai-center px16">
              <GiftIcon className="mr8 tc-primary-500" size={20} />
              <p className="p-h4 mx8">
                {t('referralengine.reward.valid', '€15 with code {{code}}', {
                  code,
                })}
                <span className="tc-grey-500">
                  {t(
                    'referralengine.reward.additionalInfo',
                    ' by {{ payoutDate }}',
                    { payoutDate }
                  )}
                </span>
              </p>
            </div>
            <div>
              <Button
                type="button"
                variant="filledWhite"
                onClick={() => onRemoveCode()}
                className={styles.removeRewardButton}
              >
                <XIcon size={24} />
              </Button>
            </div>
          </div>
          <Trans i18nKey="refferralengine.disclaimer">
            <p className="p-p tc-grey-600 mt16">
              After your policy has been active for 3 months, we’ll ask for your
              bank details to transfer you. Up to €300 per person per year.{' '}
              <a
                className="p-a"
                href={REFERRAL_ENGINE_TC_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                See terms.
              </a>
            </p>
          </Trans>
        </>
      )}
    </form>
  );
};
