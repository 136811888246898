import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { trackPageView, useTracker } from '@getpopsure/analytics';
import { LiveChatLoaderProvider } from 'react-live-chat-loader';

import '@popsure/dirty-swan/dist/index.css';
import '@getpopsure/cookie-consent/dist/style.css';

import Progress from 'components/progress';
import StickyContainer from 'components/stickyContainer';
import ReturningUserPrompt from 'pages/returningUserPrompt';
import Header from 'pages/header';
import routes from 'routes';
import CurrentSection from 'components/currentSection';
import NotFound404 from 'components/404';
import Session from 'components/session';
import SigninModal from 'components/SigninModal/index';
import { FeatureFlagsLoader } from 'components/FeatureFlagsLoader';
import { initializeTrackers } from 'components/tracker';
import 'headerScripts';
import {
  IntercomLauncher,
  setupIntercomInitialConfiguration,
} from './features/intercomWidget';
import { useSelector } from 'react-redux';
import { getEmail } from 'selectors';
import { setCheckoutStarted } from 'network/api';
import { CookieBanner } from '@getpopsure/cookie-consent';
import { PolicyShareButton } from 'components/PolicyShareButton/PolicyShareButton';
import { Toaster } from '@popsure/dirty-swan';
import { GoogleOAuthProvider } from 'features/GoogleOAuth';

const isStagingOrDev =
  process.env.REACT_APP_ENVIRONMENT === 'staging' ||
  process.env.NODE_ENV === 'development';

declare global {
  interface Window {
    Intercom: any;
  }
}

function App() {
  useTracker();
  const email = useSelector(getEmail);
  const location = useLocation();

  useEffect(() => {
    trackPageView();
  }, [location]);

  useEffect(() => {
    if (email) {
      setCheckoutStarted(email);
    }
  }, [email]);

  return (
    <LiveChatLoaderProvider
      provider="intercom"
      providerKey={process.env.REACT_APP_INTERCOM_APP_ID ?? ''}
      beforeInit={setupIntercomInitialConfiguration}
    >
      <Session>
        <ReturningUserPrompt>
          <GoogleOAuthProvider>
            <Header />
            <CurrentSection />
            <Switch>
              {routes.map(({ path, component, ...props }) => (
                <Route
                  key={path}
                  path={path}
                  exact={true}
                  component={component}
                  {...props}
                />
              ))}
              <Route component={NotFound404} path="*" />
            </Switch>
            <SigninModal />
            {isStagingOrDev && (
              <>
                <PolicyShareButton />
                <Toaster />
              </>
            )}
          </GoogleOAuthProvider>
        </ReturningUserPrompt>
        <StickyContainer />
        <Progress />
        <FeatureFlagsLoader
          featureFlagsToFetch={['private_signup_national_id_document']}
        />
        <IntercomLauncher
          color={
            process.env.REACT_APP_ENVIRONMENT === 'production'
              ? '#8e8cee'
              : '#00acc1'
          }
        />
        <CookieBanner onSave={initializeTrackers} />
      </Session>
    </LiveChatLoaderProvider>
  );
}

export default App;
