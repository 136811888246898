import { initialize, InitializeProps } from '@getpopsure/analytics';
import { ConsentObject, cookieType } from '@getpopsure/cookie-consent';

/**
 * GTM environments are meant to be used only in development/staging environments.
 * Therefore, check the current env and don't add the params in production
 */
const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';
const googleTagManagerOptions: InitializeProps['googleTagManager'] = {
  containerId: process.env.REACT_APP_GTM_ID ?? '',
  ...(isProduction
    ? {}
    : {
        auth: process.env.REACT_APP_GTM_AUTH,
        preview: process.env.REACT_APP_GTM_PREVIEW,
      }),
};

export function initializeTrackers(consent: ConsentObject) {
  let trackerOptions: InitializeProps = {
    snowplow:
      process.env.REACT_APP_COLLECTOR_APP_ID &&
      process.env.REACT_APP_COLLECTOR_URL
        ? {
            appId: process.env.REACT_APP_COLLECTOR_APP_ID,
            collector: process.env.REACT_APP_COLLECTOR_URL,
          }
        : undefined,
  };

  if (consent[cookieType.analytics_storage] === 'granted') {
    trackerOptions = {
      ...trackerOptions,
      googleAnalytics: process.env.REACT_APP_GA_TRACKING
        ? {
            trackingCode: process.env.REACT_APP_GA_TRACKING,
          }
        : undefined,
    };
  }

  if (
    consent[cookieType.ad_storage] === 'granted' ||
    consent[cookieType.analytics_storage] === 'granted' ||
    consent[cookieType.ad_personalization] === 'granted' ||
    consent[cookieType.ad_user_data] === 'granted'
  ) {
    trackerOptions = {
      ...trackerOptions,
      googleTagManager: googleTagManagerOptions,
    };
  }

  initialize(trackerOptions);
}
